<template>
  <div id="product-order" :class="`page ${$route.meta.headerType}`">
    <div class="container">
      <div class="page-header">
        <h1 class="title">{{ $__t('티켓정보') }}</h1>
        <div class="reserve-info">
          <div class="title">
            <!-- {{ $__t(`${order?.service?.product_name}`) }} -->
            {{ $__t(`${store?.store_name_localed}`) }}
          </div>
          <ul>
            <li>
              <div class="key">{{ $__t('이용 예정일') }}</div>
              <div class="value">
                {{ useDate }}
              </div>
            </li>
            <li v-if="order?.service?.extras?.festival !== 1">
              <div class="key">{{ $__t('이용 예정 시간') }}</div>
              <div class="value">
                {{ `${$moment(order?.reserve_date_start).format('HH:mm')} - ${$moment(order?.reserve_date_end).format('HH:mm')}` }}
                ({{
                  $__t('{hour}시간', {
                    hour: $moment(order?.reserve_date_end).diff(order?.reserve_date_start, 'hour')
                  })
                }})
              </div>
            </li>
            <li>
              <div class="key">{{ $__t('수량') }}</div>
              <div class="value">{{ order?.reserve_quantity }}{{ $__t('개') }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="page-body">
        <template>
          <ui-form ref="form" @submit="submitForm" @error="scrollToError" :formData="formData">
            <!-- <ui-form ref="form" @submit="submitForm" :formData="formData"> -->
            <!--예약 정보-->
            <div class="section booker">
              <div class="section-header">
                <h2>{{ $__t('예약자 정보') }}</h2>
              </div>
              <div class="section-body">
                <ui-form-list>
                  <!--이름-->
                  <ui-form-list-item>
                    <lug-text-input
                      :placeholder="formData.user_name.placeholder"
                      :type="formData.user_name.type"
                      :label="formData.user_name.label"
                      :error="formData.user_name.error"
                      v-model="formData.user_name.value"
                      :required="true"
                      :readonly="true"
                    />
                  </ui-form-list-item>
                  <!--폰번호-->
                  <ui-form-list-item>
                    <div class="lug-phone">
                      <label>
                        {{ formData.user_phone.label }}
                        <span style="color: red"> * </span>
                        <div class="body">
                          <lug-select
                            :placeholder="formData.user_dial_code.placeholder"
                            :type="formData.user_dial_code.type"
                            :items="formData.user_dial_code.items"
                            v-model="formData.user_dial_code.value"
                            :pointer="profile.phone_valid"
                            :disabled="true"
                          />
                          <lug-text-input
                            :readonly="true"
                            :placeholder="formData.user_phone.placeholder"
                            :type="formData.user_phone.type"
                            :inputmode="formData.user_phone.input_mode"
                            v-model="formData.user_phone.value"
                            @input="onChangePhoneNumber"
                          />
                          <!-- <button
                            class="btn-certify"
                            :class="{
                              disabled: formData.user_phone.verifiing || formData.user_phone.auth_key || profile.phone_valid
                            }"
                            @click.prevent="onClickRequestCertNum()"
                          >
                            {{ profile.phone_valid ? $__t('인증 완료') : $__t('인증번호 요청') }}
                          </button> -->
                        </div>
                      </label>
                      <div class="required" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                        {{ formData.user_phone.error }}
                      </div>
                      <!-- <div class="required" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                        {{
                          formData.user_phone.verifiing === false && !formData.user_phone.auth_key && profile.phone_valid === false
                            ? '인증번호 요청을 해주세요.'
                            : ''
                        }}
                      </div> -->
                    </div>

                    <div class="lug-phone-certify" v-if="formData.user_phone.auth_key">
                      <label>
                        <div class="body">
                          <div class="certNum">
                            <lug-text-input
                              :placeholder="formData.user_cert_num.label"
                              :type="formData.user_cert_num.type"
                              :inputmode="formData.user_cert_num.input_mode"
                              v-model="formData.user_cert_num.value"
                              :required="false"
                              :error="formData.user_email.error"
                            />
                            <span class="timer">
                              <certnum-timer :key="rerender" />
                            </span>
                            <span>|</span>
                            <button class="btn-recertify" @click.prevent="onClickRequestCertNum()">재전송</button>
                            <button
                              class="btn-verify"
                              :class="{
                                disabled: formData.user_cert_num.value.length !== 6
                              }"
                              @click.prevent="onClickVerifyAuthKey()"
                            >
                              인증
                            </button>
                          </div>
                        </div>
                      </label>
                      <div class="required" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                        {{ formData.user_cert_num.value.length === 6 ? '인증 버튼을 눌러주세요.' : '인증번호를 입력해주세요.' }}
                      </div>
                    </div>
                  </ui-form-list-item>
                  <!--이메일-->
                  <!-- <ui-form-list-item>
                    <lug-text-input
                      :placeholder="formData.user_email.placeholder"
                      :type="formData.user_email.type"
                      :label="formData.user_email.label"
                      :error="formData.user_email.error"
                      v-model="formData.user_email.value"
                      :required="false"
                    />
                  </ui-form-list-item> -->
                </ui-form-list>
              </div>
            </div>
            <!--결제수단 선택-->
            <div class="section pay-method">
              <div class="section-header">
                <h2>{{ $__t('결제수단 선택') | capitalizeFirstLetter }}</h2>
              </div>
              <div class="section-body">
                <div class="method-area">
                  <dl
                    v-button
                    @click="
                      $router.push({
                        name: 'ServiceReservePayMethods',
                        params: { ...$route.params }
                      })
                    "
                  >
                    <dt :class="{ required: !formData.pay_method.value }">
                      <template v-if="formData.pay_method.value">
                        {{
                          $__t(`${parseCardName(formData.pay_method.value.card_name)}`) +
                          '   ' +
                          formData.pay_method.value.card_number.substring(formData.pay_method.value.card_number.length - 4)
                        }}
                      </template>
                      <template v-else> {{ $__t('{reserve-order}.pay_method.required') }}</template>
                    </dt>
                    <dt>{{$__t('쿠폰•코드')}} <i class="fa fa-angle-right"></i></dt>
                  </dl>
                </div>
              </div>
            </div>
            <!--결제금액-->
            <div class="section price">
              <div class="section-header">
                <h2>
                  {{ $__t('{check-out-info}.payment_amount') | capitalizeFirstLetter }}
                </h2>
              </div>
              <div class="section-body">
                <div class="price-section">
                  <dl>
                    <dt class="title"></dt>
                  </dl>
                  <dl class="secondary">
                    <dt class="title">
                      {{ $__t('상품금액') | capitalizeFirstLetter }}
                    </dt>
                    <dd class="description">{{ originAmount.toLocaleString() }} {{ $__t('won') }}</dd>
                  </dl>
                  <dl class="secondary">
                    <dt class="title">{{ $__t('쿠폰') | capitalizeFirstLetter }}·{{ $__t('코드') }}</dt>
                    <dd class="description">
                      {{ discountAmount > 0 ? '-' + discountAmount.toLocaleString() : 0 }}
                      {{ $__t('won') }}
                    </dd>
                  </dl>
                </div>
                <div class="price-section">
                  <dl class="main-item">
                    <dt class="title">{{ $__t('총 결제금액') }}</dt>
                    <dd class="description">
                      <span class="price">{{ predictedAmount.toLocaleString() }} {{ $__t('won') }}</span>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="section policy">
              <div class="section-header">
                <h2>{{ $__t('Cancellation & Refund policy') }}</h2>
              </div>
              <div class="section-body">
                <ul class="precaution">
                  <li>{{ $__t('취소/환불의 경우 럭스테이를 통해서만 가능합니다.') }}</li>
                  <!-- <li>{{ $__t('미사용 상품에 한하여 사용일 1일 전까지 100% 환불 가능합니다.') }}</li> -->
                  <!-- <li>{{ $__t('당일 구매 상품의 경우, 취소 및 환불이 불가합니다.') }}</li> -->
                  <!-- <li>{{ $__t('제휴점 사정으로 서비스를 이용할 수 없는 경우, 날짜 변경 또는 비용을 환불해 드립니다.') }}</li> -->
                  <li>{{ $__t('미사용 티켓은 티켓이 만료되기 전까지 취소가 가능합니다. (만료 시각: 사용 예정일 익일 1:00 AM)') }}</li>
                </ul>
              </div>
            </div>
            <div>
              <div class="notice-order">
                <div class="notice-order__footer">
                  <ui-checkbox v-model="orderAgreed" :label="$__t('{notice-order}.checkbox.label')" class="notice-order__check" />
                  <button
                    v-button
                    type="button"
                    class="ui-submit-button"
                    :disabled="!orderAgreed"
                    :class="{
                      activated: orderAgreed && profile.phone_valid
                    }"
                    @click.prevent="onSubmit()"
                  >
                    <span class="ui-submit-button__text">
                      {{
                        $__t('{price}원 결제하기', {
                          price: this.predictedAmount.toLocaleString()
                        })
                      }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </ui-form>
        </template>
      </div>
    </div>
    <modal id="alert-modal" ref="alert-modal" submitButtonLabel="확인" @submit="closeModal('alert-modal')">
      <template v-slot:body>
        <div class="text">
          <div class="context">구매 가능한 티켓이 소진되어 해당 수량을 구매할 수 없습니다.</div>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <button>확인</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import countryCodes from '@/assets/json/country-codes.json';
import CertnumTimer from '@/components/pages/service-product/utils/CertnumTimer.vue';
import _ from 'lodash';

export default {
  name: 'ServiceReserve',
  props: ['order_id', 'store_id', 'service_id', 'festival'],

  data() {
    const AUTH_DATA = this.$store.state.auth;

    return {
      sending: false,
      scroll: false,
      rerender: 1,

      formData: {
        user_name: {
          type: 'text',
          label: this.$__t('성명'),
          value: AUTH_DATA.user_name || AUTH_DATA.user_last_name + ' ' + AUTH_DATA.user_first_name,
          validation: [{ type: 'required' }],
          error: ''
        },
        user_dial_code: {
          value: AUTH_DATA.user_dial_code || '+82',
          error: '',
          validation: [{ type: 'required' }],
          items: this.parseCountryCodes(countryCodes),
          label: this.$__t('국가 번호')
        },
        user_phone: {
          type: 'text',
          label: this.$__t('휴대폰 번호'),
          placeholder: this.$__t('휴대폰 번호를 입력해주세요'),
          validation: [{ type: 'required' }, { type: 'number' }],
          input_mode: 'number',
          error: '',
          value: AUTH_DATA.phone || AUTH_DATA.user_phone,
          mask: '###-####-####',

          cert_number: '',
          verifiing: false,
          need_to_retry: false,
          auth_key: null
        },
        user_cert_num: {
          type: 'text',
          label: this.$__t('인증번호'),
          placeholder: this.$__t('인증번호를 입력해주세요'),
          // validation: [{ type: 'number' }],
          error: '',
          input_mode: 'number',
          value: ''
        },
        user_email: {
          type: 'text',
          label: this.$__t('이메일 주소'),
          // validation: [{ type: 'required' }, { type: 'email' }],
          value: AUTH_DATA.user_email,
          error: ''
        },
        coupon_code: {
          value: ''
        },
        discount_code: {
          value: ''
        },
        pay_method: {
          items: { label: this.$__t('Credit Card'), value: 'card' },
          value: this.primaryBilling,
          validation: [{ type: 'required' }]
        }
      },

      duration: null,

      errors: [],

      listQuery: {
        payMethod: {
          page: 1,
          size: 10
        }
      },

      orderAgreed: false
    };
  },

  watch: {
    formData: {
      handler: _.debounce(function () {
        this.$store.commit('resourceForm/SET_ITEM', {
          data: {
            form_id: 'reserve_form',
            user_name: this.formData.user_name.value,
            user_dial_code: this.formData.user_dial_code.value,
            user_phone: this.formData.user_phone.value,
            user_email: this.formData.user_email.value
          }
        });
      }, 100),
      deep: true
    },
    primaryBilling(value) {
      this.$data.formData.pay_method.value = value;
    }
  },

  computed: {
    reserveFormState() {
      const RESERVE_FORM_STATE = this.$store.getters['resourceForm/GET_ITEM']({
        key: 'form_id',
        value: 'reserve_form'
      });
      this.formData.pay_method.value = RESERVE_FORM_STATE.pay_method;
      return RESERVE_FORM_STATE;
    },
    profile() {
      return this.$store.state.auth || {};
    },

    order() {
      const order = this.$store.getters['orders/GET_ITEM']({
        key: 'reserve_id',
        value: this.order_id
      });

      return order;
    },
    store() {
      return (
        this.$store.getters['stores/GET_ITEM']({
          key: 'id',
          value: this.store_id
        }) || {}
      );
    },
    useDate() {
      let result = '';
      result = this.$moment(this.order?.reserve_date_start).format('YYYY.MM.DD');
      let day = this.$moment(this.order?.reserve_date_start).day();
      switch (day) {
        case 1:
          if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (월)';
          } else {
            result = result + ' (Mon)';
          }
          break;

        case 2:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (화)';
          } else {
            result = result + ' (Tue)';
          }
          break;
        case 3:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (수)';
          } else {
            result = result + ' (Wed)';
          }
          break;
        case 4:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (목)';
          } else {
            result = result + ' (Thur)';
          }
          break;
        case 5:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (금)';
          } else {
            result = result + ' (Fri)';
          }
          break;
        case 6:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (토)';
          } else {
            result = result + ' (Sat)';
          }
          break;
        case 7:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (일)';
          } else {
            result = result + ' (Sun)';
          }
          break;
      }

      return result;
    },

    primaryBilling() {
      const billing = this.$store.getters['billings/PRIMARY_BILLING'];
      // console.log('billing', billing);
      if (!billing) {
        return null;
      }
      billing.billing_id = billing.uid;
      return billing;
    },
    predictedAmount() {
      return this.order?.predicted_amount || 0;
    },
    discountAmount() {
      return this.order?.payment?.discount || 0;
    },
    originAmount() {
      return this.order?.payment?.origin || 0;
    }
  },

  async created() {
    try {
      await this.fetchProfile();
      let order = await this.fetchOrder();
      this.setReserveFormState(order);
      await this.fetchBillings(); //getPayMethods와 동일. card list 불러오기
      this.loadFormStateData();
    } catch (e) {
      console.error(e);
    }
  },

  async mounted() {
    this.$analytics.logEvent('mobile reservation info view');
  },

  beforeMount() {},
  beforeDestroy() {},

  methods: {
    openModal(ref) {
      this.$refs[ref].open(ref);
    },

    closeModal(ref) {
      this.$refs[ref].close();
    },

    fetchProfile() {
      return this.$store.dispatch('auth/profile', {});
    },

    fetchOrder() {
      return this.$store.dispatch('orders/reserve', {
        id: this.order_id
      });
    },

    fetchBillings() {
      return this.$store.dispatch('billings/getCardList', {
        query: {
          page: 1,
          size: 10
        }
      });
    },

    scrollToError() {
      setTimeout(() => {
        const errorList = document.querySelectorAll('.ui-form-list-item div.error, .required');
        if (errorList.length >= 0) {
          const elOffset = $(errorList[0]).offset().top;
          const elHeight = $(errorList[0]).height();
          const windowHeight = $(window).height();
          let offset;
          if (elHeight < windowHeight) {
            offset = elOffset - (windowHeight / 2 - elHeight / 2);
          } else {
            offset = elOffset;
          }
          $('html, body').animate({ scrollTop: offset }, 200);
        }
      }, 0);
    },

    parseCardName(cardName = '') {
      const regExp = /\[([^)]+)\]/;
      let matches = regExp.exec(cardName);
      if (Array.isArray(matches) && matches.length > 0) {
        return matches[1];
      }
      return cardName;
    },

    parseCountryCodes(countryCodes) {
      let _dial_code =
        countryCodes.map((o) => ({
          label: `${o.dial_code} (${o.code})`,
          value: o.dial_code
        })) || [];
      _dial_code.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      _dial_code.forEach((o) => {
        //
        if (o.name === 'Korea, Republic of') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.unshift(o);
        }
        //
        if (o.name === 'Others') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.push(o);
        }
      });

      return _dial_code;
    },

    onChangePhoneNumber() {
      this.formData.user_phone.verifiing = false;

      this.formData.user_phone.need_to_retry = false;
      this.formData.user_phone.auth_key = null;
    },

    async onClickRequestCertNum(e) {
      let res;

      try {
        this.formData.user_phone.verifiing = true;

        res = await this.$store.dispatch('serviceProduct/authNumRequest', {
          user_name: this.formData.user_name.value,
          phone: this.formData.user_phone.value
        });
        this.rerender = this.rerender + 1;
        this.formData.user_phone.auth_key = res.auth_key;
        this.formData.user_phone.need_to_retry = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.formData.user_phone.verifiing = false;
      }

      return;
    },

    async onClickVerifyAuthKey() {
      let res;

      try {
        res = await this.$store.dispatch('serviceProduct/certRequest', {
          auth_key: this.formData.user_phone.auth_key,
          auth_code: this.formData.user_cert_num.value
        });

        if (res.message === 'invalid_code') {
          alert('인증코드가 일치하지 않습니다.');
          return;
        }

        this.formData.user_phone.auth_key = null;

        await this.fetchProfile();

        alert('인증 완료되었습니다.');
      } catch (e) {
        console.error(e);
      }
    },
    onSubmit() {
      return this.$refs.form.submit();
    },

    submitForm: _.debounce(async function () {
      // if (this.profile.phone_valid === false) {
      //   // alert('핸드폰 인증을 진행해주세요.');
      //   // return;
      //   return this.scrollToError();
      // }

      try {
        const order = await this.$store.dispatch('serviceProduct/complete', {
          receipt_id: this.order.reserve_id,
          reserve: {
            user_name: this.formData.user_name.value,
            user_phone: this.formData.user_phone.value,
            user_email: this.formData.user_email.value
          },
          billing: { billing_id: this.formData.pay_method.value.uid || 0 }
        });

        this.$router.push({
          name: 'ServiceProductTicketSingle',
          params: {
            id: order.ticket.ticket_code
          },
          query: {
            from: 'Home'
          }
        });
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', {
          message: e,
          status: 'error'
        });
      } finally {
        this.$data.sending = false;
      }
    }, 100),

    onClickSubmit() {
      this.onSubmitForm();
    },
    loadFormStateData() {
      let { user_name, user_dial_code, user_phone, user_email, coupon_code, discount_code, pay_method, reserve_info_visible } =
        this.reserveFormState || {};
      try {
        this.formData.user_name.value = user_name || this.formData.user_name.value;
        this.formData.user_dial_code.value = user_dial_code || this.formData.user_dial_code.value;
        this.formData.user_phone.value = user_phone || this.formData.user_phone.value;
        this.formData.user_email.value = user_email || this.formData.user_email.value;
        this.formData.coupon_code.value = coupon_code || this.formData.coupon_code.value;
        this.formData.discount_code.value = discount_code || this.formData.discount_code.value;
        this.formData.pay_method.value = pay_method || this.primaryBilling || this.formData.pay_method.value;
        this.reserveInfoVisible = reserve_info_visible || this.reserveInfoVisible;
      } catch (e) {}
    },
    setReserveFormState(res) {
      return this.$store.commit('resourceForm/SET_ITEM', {
        data: {
          form_id: 'reserve_form',
          store_id: res.product_id,
          date_start: res.reserve_date_start,
          date_end: res.reserve_date_end,
          quantity_small: parseInt(res.reserve_quantity) || 0,
          quantity_big: parseInt(res.reserve_over_quantity) || 0
        }
      });
    },
    async getPayMethods() {
      // console.log('getpaymethods');
      try {
        return this.$store.dispatch('billings/getCardList', {
          query: this.listQuery.payMethod
        });
      } catch (e) {}
    }
  },

  components: {
    CertnumTimer
  }
};
</script>
<style scoped lang="scss">
#product-order {
  @include container('& > .container', $max-content-width);

  & > .container {
    height: 100%;
    background-color: $color-white;

    .page-header {
      padding: unit(14) unit(16);
      flex-direction: column;
      // background-color: #f1f5f5;

      .title {
        font-weight: 500;
        font-size: unit(22);
        line-height: unit(32);
        color: $color-gray-9;
      }

      .secondary {
        margin-top: unit(6);
        font-size: unit(14);
        line-height: unit(20);
        color: #878d91;
      }

      .reserve-info {
        .title {
          margin-top: unit(20);
          margin-bottom: unit(19);
          font-size: unit(16);
          font-weight: 600;
          line-height: unit(24);
        }

        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .key {
            font-weight: 400;
            font-size: unit(14);
            line-height: unit(20);
          }
          .value {
            font-weight: 600;
            font-size: unit(16);
            line-height: unit(24);
          }
        }

        &__content {
          padding: unit(20) unit(16);

          .content-header {
            font-weight: 500;
            font-size: unit(14);
            line-height: 1.57;
          }

          .content-body {
            margin-top: unit(6);
            font-size: unit(14);
            line-height: 1.57;
            letter-spacing: -0.04em;
            color: #878d91;
          }
        }

        &__button {
          height: unit(50);
          border-top: solid 1px #e1e4e6;
          color: #878d91;
        }
      }
    }

    .page-body {
      margin-top: unit(20);
      padding: 0;
    }

    .section {
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: unit(10);
        background: #f1f5f5;
      }

      .section-header {
        display: flex;
        align-items: flex-start;
        min-height: unit(50);
        padding: unit(13) unit(16);

        h2 {
          flex: 1;
          font-weight: 500;
          font-size: unit(16);
          line-height: unit(23);
          color: $color-gray-9;
        }

        .arrow {
          flex: 0 0 2 * $unit;
          width: 2 * $unit;
          height: 2 * $unit;
        }
      }

      .section-body {
        padding: unit(20) unit(16);
        .lug-phone {
          .body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: unit(8);

            .lug-select {
            }

            .lug-textinput {
              width: 0;
            }

            .btn-certify {
              background-color: #48d9eb;
              border-radius: 16px;
              color: white;
              min-width: unit(80);

              &.disabled {
                pointer-events: none;
                opacity: 0.5;
              }
            }
          }
        }
        .lug-phone-certify {
          margin-top: unit(8);

          .body {
            .certNum {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              gap: unit(8);
              width: 100%;
              height: unit(34);
              span {
                color: #48d9eb;
              }

              input {
                border-bottom: solid 1px #eaeeef;
              }
              .btn-recertify {
                color: #48d9eb;
                width: unit(50);
              }
              .btn-verify {
                background-color: #48d9eb;
                border-radius: 16px;
                color: white;
                max-width: unit(80);
                height: unit(33);
                &.disabled {
                  pointer-events: none;
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }

      &.reserve {
        padding: 0;

        .section-header {
          border-bottom: solid 1px #eaeeef;
        }

        .section-body {
          padding-bottom: unit(30);
          display: flex;
          gap: unit(40);

          .content {
            flex: 1;
            min-width: 0;

            .primary {
              font-size: unit(14);
              line-height: unit(20);
              @include ellipsis(1);
            }

            .secondary {
              padding-top: unit(8);
              font-size: unit(14);
              line-height: unit(20);
              color: #878d91;
              @include ellipsis(2);
            }
          }

          .button {
            align-self: flex-end;
            flex: 0 0 auto;

            button {
              border: 1px solid #008fff;
              border-radius: unit(30);
              padding: unit(3) unit(9);
              color: #008fff;
              line-height: unit(17);
              text-transform: uppercase;
              font-weight: 500;
            }
          }
        }
      }

      &.booker {
        border: 0;

        .ui-form-list-item {
          & + .ui-form-list-item {
            margin-top: unit(28);
          }
        }
      }

      &.pay-method {
        .section-body {
          padding-bottom: unit(30);
          min-height: unit(62);
          // .method-area {
          //   display: flex;
          // }
        }

        dl {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          line-height: normal;
          gap: unit(20);

          & + dl {
            margin-top: unit(12);
          }

          dt {
            font-size: unit(14);
            line-height: unit(24);
            flex-shrink: 0;
            // min-width: unit(100);

            &.required {
              color: #ff5d5d;
            }
          }

          dd {
            display: flex;
            justify-content: flex-end;
            gap: unit(2);
            min-width: unit(100);

            .icon {
              display: inline-block;
              width: unit(24);
              height: unit(24);
              vertical-align: middle;
              bottom: unit(1);
              min-width: 0;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .text {
              min-width: 0;
              font-size: unit(14);
              line-height: unit(24);
              color: #878d91;
            }
          }
        }
      }

      &.price {
        .section-header {
          display: block;
        }
        .section-body {
          padding: 0 unit(16);
        }

        dl {
          display: flex;
          align-items: flex-start;
          line-height: normal;

          & + dl {
            margin-top: unit(12);
          }

          dt {
            flex: 1;
            font-weight: 500;
            font-size: unit(14);
            line-height: 1.57;
            letter-spacing: -0.04em;
            color: #61676c;

            .title__button {
              margin: 0 unit(2);
              top: unit(4);
              width: unit(18);
              height: unit(18);
              object-fit: contain;
              display: inline-block;

              & > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          dd {
            flex: 0 0 auto;
            font-size: unit(14);
            line-height: 1.57;
            letter-spacing: -0.04em;
          }

          &.secondary {
            dt {
              color: #292a2b;
              font-size: unit(14);
              font-weight: normal;
            }
          }
          .price {
            font-weight: 600;
            font-size: unit(16);
            line-height: unit(24);
          }
        }

        .price-section {
          padding: unit(20) 0;

          &:first-of-type {
            padding-top: 0;
          }

          & + .price-section {
            border-top: solid 1px #e1e4e6;
          }
        }

        .main-item {
          display: flex;
          align-items: center;

          .title {
            font-size: unit(14);
            font-weight: 500;
            color: $color-black;
          }

          .secondary {
            font-size: unit(12);
            line-height: unit(26);
            text-align: right;
            color: #008fff;

            strong {
              font-weight: bold;
              font-size: unit(18);
              line-height: unit(26);
              color: #008fff;
            }
          }
        }
        .section-header {
          .primary {
            color: $color-gray-9;
          }

          .secondary {
            color: #008fff;
            font-size: unit(12);

            strong {
              font-weight: bold;
              font-size: unit(16);
            }
          }
        }
      }

      &.condition {
        &::after {
          display: none;
        }

        .section-header {
          display: flex;
          align-items: center;

          h3 {
            flex: 1;
            font-weight: 500;
            font-size: unit(12);
          }

          button {
            flex: 0 0 auto;
            width: auto;
            display: inline-block;
            padding: unit(3) unit(9);

            & > * {
              font-size: unit(12);
              color: #a9afb3;
              text-decoration-line: underline;
            }
          }
        }

        .section-body {
          h4 {
            font-weight: 500;
            font-size: unit(12);
            line-height: unit(17);
            color: #878d91;
            margin-bottom: unit(12);
          }

          p {
            line-height: unit(17);
            color: #878d91;

            & + p {
              margin-top: unit(16);
            }
          }
        }
      }

      &.footer {
        background-color: #f1f5f5;
        padding: unit(30) unit(16);

        &::after {
          // height: unit(70);
        }

        .footer-item {
          padding: unit(16);
          background-color: $color-white;

          &--transparent {
            background-color: transparent;
            padding-bottom: 0;
            line-height: 1.5;
            letter-spacing: -0.02em;
            color: #878d91;
          }

          & + .footer-item {
            margin-top: unit(20);
          }

          .title {
            font-weight: 500;
            font-size: unit(14);
            letter-spacing: -0.02em;
            display: flex;
            flex-direction: column;

            img {
              width: unit(24);
              height: unit(24);
              object-fit: contain;
              bottom: unit(1);
            }

            &__text {
              margin-top: unit(3);
            }
          }

          .description {
            margin-top: unit(6);
            font-weight: normal;
            letter-spacing: -0.02em;
            line-height: unit(17);

            strong {
              font-weight: 700;
            }
          }

          & + .footer-item {
            border-top: solid 1px #eaeeef;
          }

          &:last-child {
            color: #878d91;
          }
        }
      }
    }

    .notice-order {
      &__footer {
        .notice-order__check {
          &.checked {
            .checkbox {
              background-color: #48d9eb;
            }
          }
        }
        .ui-submit-button {
          border-radius: unit(30);

          &.activated {
            background-color: #48d9eb;
            cursor: pointer;
          }
        }
      }
    }
  }

  .precaution {
    li {
      &::before {
        content: ' • ';
        font-size: unit(14);
        padding: unit(0) unit(4);
      }
    }
  }
}
</style>
